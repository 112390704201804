<template>
  <v-app>
    <RaiNotice />
    <v-app-bar app elevate-on-scroll>
      <v-app-bar-nav-icon @click="$router.back()">
        <v-icon>$back</v-icon>
      </v-app-bar-nav-icon>
      <RaiLogo height="30" />
      <v-menu min-width="200" offset-y bottom left>
        <template #activator="{ on }">
          <v-btn icon data-testid="rightMenuBtn" v-on="on">
            <v-icon>$more</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item data-testid="signOutBtn" @click="handleSignout">
            <v-list-item-title>Sign Out</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main>
      <v-fade-transition>
        <router-view :store-id="storeId" />
      </v-fade-transition>
    </v-main>
    <RaiSnackbar />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { hasActiveEmployeeAccess } from "@/mixins/employee";
import RaiLogo from "@/components/RaiLogo.vue";
import RaiNotice from "@/components/util/RaiNotice.vue";
import RaiSnackbar from "@/components/util/RaiSnackbar.vue";

export default {
  name: "OnboardingLayout",
  components: {
    RaiLogo,
    RaiNotice,
    RaiSnackbar,
  },
  mixins: [hasActiveEmployeeAccess],
  provide() {
    return {
      computedActiveEmployeeCan: () => this.computedActiveEmployeeCan,
    };
  },
  props: {
    storeId: {
      type: [String, Number],
      required: true,
    },
  },
  methods: {
    ...mapActions("auth", ["signOut"]),
    ...mapActions("snackbar", ["showSnackbar"]),
    handleSignout() {
      this.signOut()
        .then(() => this.$router.push({ name: "auth.login" }))
        .then(() => this.$apollo.getClient().resetStore())
        .then(() => this.showSnackbar({ text: "You have signed out." }));
    },
  },
};
</script>
