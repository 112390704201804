<template>
  <v-slide-y-transition mode="in-out">
    <v-system-bar
      v-if="nextNotice"
      class="rai-notice"
      :color="nextNotice.color"
      role="alert"
      app
      window
      dark
    >
      <v-spacer></v-spacer>
      <span class="text-body-2 font-weight-bold">{{ nextNotice.text }}</span>

      <template v-if="nextNotice.actionText">
        <v-btn text color="white" @click="nextNotice.action.call()">
          {{ nextNotice.actionText }}
        </v-btn>
        <v-spacer></v-spacer>
      </template>
      <template v-else>
        <v-spacer></v-spacer>
      </template>
    </v-system-bar>
  </v-slide-y-transition>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "RaiNotice",
  computed: {
    ...mapGetters("notice", ["nextNotice"]),
  },
};
</script>

<style lang="scss">
.rai-notice {
  z-index: 5; // -> same as v-app-bar
}
</style>
