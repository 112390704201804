<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("snackbar", [
      "timeout",
      "multiline",
      "text",
      "button",
      "color",
      "visible",
      "action",
    ]),
    snack: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$store.commit("snackbar/visible", val);
      },
    },
  },
  methods: {
    async performAction() {
      if (!!this.action) {
        this.action.call();
      }
      this.$nextTick(() => {
        this.snack = false;
      });
    },
  },
};
</script>

<template>
  <v-snackbar
    v-model="snack"
    app
    bottom
    :left="$vuetify.breakpoint.mdAndUp"
    :color="color"
    :tile="$vuetify.breakpoint.xsOnly"
    :timeout="timeout"
    :multi-line="multiline === true"
  >
    {{ text }}
    <template v-slot:action="{ attrs }">
      <v-btn
        v-if="button"
        text
        dark
        color="primary lighten-2"
        v-bind="{ attrs }"
        @click="performAction"
      >
        {{ button }}
      </v-btn>
    </template>
  </v-snackbar>
</template>
